.min-w-40 {
  min-width: 40px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.normal-case {
  text-transform: lowercase !important;
}
.spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}
.tableContainerRoot{
	max-height: 600px;
	border: 1px solid #c1c1c1;
	border-radius: 5px;
  margin-bottom: 20px;
}
.spinner-wrapper.dashboard {
  position: absolute;
  width: 100px;
  min-height: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  overflow: hidden;
}
.dash-dropdown .MuiSelect-select:focus {
  background: transparent !important;
}

.spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

.spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

.spinner-wrapper .spinner .inner .left,
.spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

.spinner-wrapper .spinner .inner .left .half-circle,
.spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 10px solid #009fe2;
  border-bottom-color: transparent;
  border-radius: 50%;
}

.spinner-wrapper .spinner .inner .left {
  left: 0;
}

.spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.spinner-wrapper .spinner .inner .right {
  right: 0;
}

.spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1)
    infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {
  0%,
  100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {
  0%,
  100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}


.datePickerComponent {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  line-height: 1.1875em;
  color: #000;
  padding: 10px 12px;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
  text-align: left;
  font-size: 12px !important;
  width: 100%;
}
.datePickerComponent.red-border {
  border-color: #f44336 !important;
}
.dateComponent {
  width: 100%;
  margin: 0 3px;
  position: relative;
}
.react-datepicker-wrapper{
  width: 100%;
}
.dateInputLable {
  position: absolute;
  left: 13px;
  top: 12px;
  letter-spacing: 0.6px;
  z-index: 0;
  border-radius: 4px;
  transform: translate(0px, 0px) scale(1);
  color: rgb(164 164 164 / 72%);
  font-weight: 300;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  visibility: hidden;
}
.dateInputLable.outLineDateInputLable {
  transform: translate(8px, -6px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  transform-origin: top left;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0px 6px;
  color: rgba(0, 0, 0, 0.54);
  background: #fff;
  visibility: visible;
}
.react-datepicker {
  border: 0px !important;
  background: #fff !important;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
  border-radius: 2px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.5rem !important;
  line-height: 1.5rem !important;
  z-index: 999 !important;
  position: relative !important;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
}
.react-datepicker-popper .react-datepicker__triangle {
  top: 0;
  margin-top:0;
  bottom: inherit !important;
}
.react-datepicker-popper[data-placement^="top"]{
  padding-top: 46px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after{
  display: none !important;
}
.textareaStyle{
  width: 100%;
  resize: none;
  border: 1px solid #f1f1f1!important;
  padding: 6px 12px 5px!important;
  border-radius: 6px !important;
}
.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #f1f1f1 !important;
  padding: 8px 8px 4px 8px !important;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
}
.timeComponent .react-datepicker__header {
  display: none;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin: 2px 0px 2px !important;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 6px 10px !important;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
}
.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 90px !important;
}
.react-datepicker-time__header {
  margin: 0px !important;
}

.react-datepicker__month {
  margin: 0.4rem !important;
}
.react-datepicker-popper {
  z-index: 999 !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #566672 !important;
}
.react-datepicker__navigation--next {
  border-left-color: #566672 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: #192d3e;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto !important;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  outline: none !important;
  border: none;
}
.react-datepicker--time-only .react-datepicker__triangle {
  height: inherit !important;
}
.react-datepicker .react-datepicker__triangle::before{
  bottom: -8px;
  transform: rotate(-180deg) !important;
  -webkit-transform: rotate(-180deg) !important;
  -moz-transform: rotate(-180deg) !important;
  -ms-transform: rotate(-180deg) !important;
  -o-transform: rotate(-180deg)!important;
}

.errorDisplay {
  background-color: #fce8e6;
  border-color: #fce8e6;
}
.confirmButtonStyle{
  margin-left: 10px !important;
  padding: 5px 8px !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
}
.mainDialogTitle{
  background: #192d3e;
  color: #fff;
  padding: 10px 16px !important;
}
.mainDialogBodyTitle{
  color: rgb(25, 45, 62);
  font-size: 20px !important;
  line-height: 1.6 !important;
  text-transform: capitalize;
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dragDropTable .MuiTableCell-sizeSmall {
  padding: 6px 10px;
}
.dragDropTable thead {
  background: #f5f8fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dragDropTable tbody tr:hover {
  background: #f5f8fa !important;
}
.dragDropTable tbody tr:last-child td {
  border-bottom: 0px;
}
.dragDropTable tr th,
.dragDropTable tr td {
  border-color: rgb(223, 227, 235);
  height: 50px;
  min-height: 50px;
  word-break: break-all;
}
.dragDropTable tr th {
  background: #f5f8fa !important;
}

.reactTable-headerText {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
}
.reactTable-headerText p{
  font-size: 14px;
  font-weight: bold;
  color: #3361FF !important;
  line-height: 17px;
}

.ReactTable .column-header div span.material-icons.sortableArrow {
  transform: rotate(90deg);
  font-size: 1.1rem;
  transition: 0.3s all;
}

.ReactTable .column-header.-sort-asc div span.material-icons.sortableArrow {
  transform: rotate(-90deg);
}

.ReactTable .rt-th.rt-resizable-header > div {
  justify-content: space-between;
}
.ReactTable .rt-th.rt-resizable-header div span.material-icons.sortableArrow {
  transform: rotate(90deg);
  font-size: 1.1rem;
  transition: 0.3s all;
}

.ReactTable
  .rt-th.rt-resizable-header.-sort-asc
  div
  span.material-icons.sortableArrow {
  transform: rotate(-90deg);
}

.ReactTable.-striped .rt-tr.-odd {
  background-color: #fff;
}

.reactSelectStyle > div > div div {
  width: auto !important;
  position: relative !important;
  overflow-y: visible !important;
}

.MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.dividerStyle {
  margin: 24px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.w-100 {
  width: 10rem !important;
}


.largeAvatar {
  height: 125px;
  width: 125px;
  background-color: transparent !important;
  margin: 0px auto 15px;
}

.w-128 {
  width: 128px;
}

#fuse-splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.nofoundImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 8px;
}
.nofoundContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
}
.nofoundBackButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 24px;
}

.backButtonLink {
  color: #00a0e3 !important;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
.backButtonLink:hover {
  text-decoration: underline;
}
.userNotFoundBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}






/**************** new style ******************/
.loginFormWraper{
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.loginFormHeader{
  padding-bottom: 20px;
}
.loginFormWraper .loginFormPaper {
  background: #FFFFFF !important;
  border-radius: 8px;
  opacity: 1;
  box-shadow: none !important;
  padding: 45px 32px;
}
.defaultButton{
  height: 40px;
  border-radius: 4px !important;
  color: #F6F7F9 !important;
  font-size: 12px !important;
  background: rgb(88, 134, 212) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  width: 100%;
  text-transform: capitalize !important;
  padding: 6px 40px !important;
}
.cssOrStyle {
  font-weight: 700;
  color: #3361FF;
  margin: 0px 8px;
}

.signinButtonImg {
  background-image: url("./assets/img/google-logo.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25px;
  left: 1px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  vertical-align: middle;
  width: 38px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dividerStyle hr.MuiDivider-root{
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #3361FF;
  opacity: 0.3;
}
.drawerWraper .MuiDrawer-paper{
  min-width: 280px;
  border-right: 1px solid #F0F2F7 !important;
  box-shadow: none;
}
.drawerWraper hr.MuiDivider-root{
  background-color: #F0F2F7;
  margin-bottom: 5px;
}
.drawerWraper .drawerTitle{
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 34px;
  color: #3361FF;
  justify-content: center;
  padding: 8px 25px;
  margin: 0px;
  letter-spacing: 1.3px;
}
.drawerWraper .drawerlist {
  padding: 20px;
}
.drawerWraper .drawerlist a{
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3361FF;
}
.drawerWraper .drawerlist a div.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #FAFBFC;
}
.drawerWraper .drawerlist a div.MuiListItem-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #3361FF;
  border-radius: 8px;
  height: 44px;
}
.drawerWraper .drawerlist a.active div.MuiListItem-button{
  background: #377DFF;
  color: #F6F7F9;
}
.drawerWraper .drawerlist a div.MuiListItem-button .MuiListItemIcon-root{
  margin: 0px 15px 0px 0px;
}
.drawerWraper .drawerlist a div.MuiListItem-button .MuiListItemText-root{
  margin: 0px;
}
.drawerWraper .drawerlist a div.MuiListItem-button .MuiListItemText-root span.MuiTypography-root.MuiListItemText-primary{
  font-size: 14px;
  font-weight: 500;
}
.drawerWraper .drawerlist a div.MuiListItem-button .MuiListItemIcon-root .MuiSvgIcon-root{
  fill: #3361FF;
}
.drawerWraper .drawerlist a.active div.MuiListItem-button .MuiListItemIcon-root .MuiSvgIcon-root{
  fill: #fff;
}


.headerWraper .MuiPaper-root{
  background: #fff;
  box-shadow: none;
}
.headerWraper .MuiPaper-root .MuiToolbar-root{
  padding: 6px 24px;
}
.headerWraper .MuiPaper-root .MuiToolbar-root h1{
  color: #405580;
  font-size: 20px;
  font-weight: bold;
}



.headerWraper button.userDetailsStyle {
  height: 54px;
  padding: 5px 0 5px 12px !important;
  color: #405580 !important;
  font-size: 14px;
  font-weight: bold;
}
.headerWraper button.userDetailsStyle span.MuiIcon-root {
  line-height: 30px !important;
  font-size: 2rem !important;
  margin-left: 5px !important;
}
.headerWraper button.userDetailsStyle:hover{
  background-color: transparent;
}
.reactTableWraper{
  border: 1px solid #F0F2F7;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
}

.reactTableWraper .ReactTable{
  border: 0px;
}
.reactTableWraper .ReactTable .rt-thead.-header{
  box-shadow: none;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 5px;
}
.reactTableWraper .ReactTable .rt-thead .rt-resizable-header{
  background: rgb(51 97 255 / 10%);
  font-size: 14px;
  font-weight: bold;
  color: #3361FF !important;
  box-shadow: none;
  border: 0px !important;
  padding: 12px 10px !important;
  height: 40px;
  text-align: left;
}
.reactTableWraper .ReactTable .rt-tbody{
  min-height: 70px;
}
.reactTableWraper .ReactTable .rt-noData{
  font-size: 14px;
  color: #405580;
  font-weight: 400;
  padding: 8px 20px;
  display: flex;
  right: 2px;
  margin-left: 0;
  margin-right: 0px;
  transform: inherit;
  top: 52px;
  bottom: 48px;
  left: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 4px;
  background: rgb(250 251 252);
}

.reactTableWraper .ReactTable .rt-thead .rt-tr .rt-th{
  position: relative;
}


.reactTableWraper .ReactTable .-pagination{
  box-shadow: none;
  border: 0px;
  padding: 0px;
}
.reactTableWraper .ReactTable .rt-tr-group{
  margin: 5px 0px;
  border: 1px solid #F0F2F7 !important;
  border-radius: 4px;
}

.reactTableWraper .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td{
  border-right: 0px;
  position: relative;
}

.reactTableWraper .ReactTable .rt-thead .rt-tr .rt-th::after,.reactTableWraper .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td::after {
  content: "";
  position: absolute;
  width: 1px;
  background: rgb(51 97 255 / 10%);
  right: 0;
  top: 10px;
  bottom: 10px;
}
.reactTableWraper .ReactTable .rt-thead .rt-tr .rt-th:last-child::after ,.reactTableWraper .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child:after{
  display: none;
}
.reactTableWraper .ReactTable .rt-tr{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: left;
}

.reactTableWraper .ReactTable .rt-tbody .rt-tr-group:last-child{
  margin-bottom: 10px !important;
}

.reactTableWraper .ReactTable .-pagination .-btn{
  border-radius: 4px;
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,0.6);
  background-color: #eaefff !important;
  width: 100px;
}

.reactTableWraper .ReactTable .pagination-bottom{
  margin: auto;
}
.reactTableWraper .ReactTable .pagination-bottom .-pagination .-center{
flex-wrap: nowrap;
}


.reactTableWraper .ReactTable .-pagination input, .reactTableWraper .ReactTable .-pagination select{
  border: 1px solid rgb(64 85 128 / 10%);
  background: #fff;
  padding: 5px 7px;
  border-radius: 3px;
  outline-width: 0;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
}
.reactTableWraper .ReactTable .-pagination .-pageJump{
  margin: 0px 8px;
  font-weight: 600;
}
.reactTableWraper .ReactTable .-pagination .-pageInfo{
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
  font-size: 12px;
  color: #405580;
  font-weight: 600;
}

.reactTableWraper .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td span.tableMainName{
  min-width: 220px;
  display: flex;
}

.drawerWraper div.MuiDrawer-root.MuiDrawer-docked{
  height: 100%;
}

.headerWraper button.userDetailsStyle .MuiButton-label div.items-start span{
  color: #405580 !important;
  font-size: 14px;
  font-weight: bold;
}
.header-Wraper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.header-Wraper .header-Wraper-left p{
  font-size: 12px;
  color: #405580;
  font-weight: 500;
  line-height: 16px;
}
.header-Wraper .header-Wraper-right{
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-Wraper .header-Wraper-right .formInputWraper{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3361FF;
  border-radius: 4px;
  height: 32px;
  overflow: visible !important;
}
.header-Wraper .header-Wraper-right .MuiInputBase-root{
  width: 197px;
}
.header-Wraper .header-Wraper-right .MuiInputBase-root input{
  padding: 6px 10px;
}
.header-Wraper .header-Wraper-right button.MuiButtonBase-root{
  padding: 5px;
  display: flex;
  align-items: center;
  background: #377DFF;
  border-radius: 0px;
  flex-direction: column;
  width: 32px;
  color: #fff;
  height: 32px;
}

.header-Wraper .header-Wraper-right .header-Wraper-filterDiv button{
  width: 32px;
  height: 32px;
  border-radius: 4px;
  min-width: 32px;
  box-shadow: none;
  border: 0px;
  margin-right: 10px;
}

.header-Wraper .header-Wraper-right button.MuiButtonBase-root span.material-icons , .header-Wraper .header-Wraper-right .header-Wraper-filterDiv button.MuiButtonBase-root span.material-icons{
  color: #fff;
  font-size: 1.2rem;
}

.header-Wraper-filterDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.header-Wraper-filterDiv li{
  padding: 0px;
  margin-right: 10px;
}
.header-Wraper-filterDiv li div.MuiChip-root{
  border-radius: 4px;
  background: rgb(55 125 255 / 10%);
}
.header-Wraper-filterDiv li div.MuiChip-root .MuiChip-deleteIcon{
  color: #377DFF;
  height: 18px;
  width: 18px;
}

.popover-Wraper{
  overflow: visible !important;
  background: #000;
  border-radius: 8px !important;
  border: 0px;
}
.popoverContentwraper{
  min-width: 220px;
  padding: 20px;
  box-shadow: 0px 3px 6px #f0f2f7;
  border: 1px solid #F0F2F7;
  border-radius: 8px;
  overflow: visible !important;
  width: 220px;
}

.MUISelect{
  height: 36px;
  overflow: hidden;
}
.popoverFormDatePickerWraper .react-datepicker-wrapper .react-datepicker__input-container input{
  height: 36px;
  border: 1px solid #F0F2F7;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.popoverContentwraper .typographylable{
  color: #405580;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.popoverContentwraper .popoverFormDatePickerWraper{
  display: flex;
}
.justifyContentEnd{
  justify-content: flex-end !important;
}
.popoverContentwraper .popoverWraperFooter{
  display: flex;
  justify-content: space-between;
  margin-top: 25px
}
.popoverContentwraper .popoverWraperFooter button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  width: 100%;
  text-transform: capitalize;
  height: 30px;
}
.popoverContentwraper .popoverWraperFooter button.cancelBtn{
  margin-right: 10px;
  border-color: #3361FF;
  color: #3361FF;
  border-radius: 4px;
}
.popoverContentwraper .popoverWraperFooter button.filterBtn{
  margin-left: 10px;
  background-color: #3361FF !important;
  border-radius: 4px;
}

.customTextField .MuiOutlinedInput-notchedOutline{
  border-color: #F0F2F7;
  border-radius: 4px;
}

#fromDate::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #3361FF !important;
}
#fromDate:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #3361FF !important;
  opacity: 1;
}
#fromDate::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #3361FF !important;
  opacity: 1;
}
#fromDate:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3361FF !important;
}

.customTextField label{
  display: none;
}
button.actionbtnCss{
  background: transparent !important;
}
button.actionbtnCss span.MuiTouchRipple-root{
  display: none !important;
}

.primaryButton{
  background-color: #3361FF !important;
  border: 1px solid #3361FF !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  height: 32px !important;
  padding: 6px 12px !important;
}

.tabsWraper .tabsWraperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.tabsWraper .tabsWraperHeader .MuiTabs-root .MuiTabs-indicator{
  display: none;
}
.tabsWraper .tabsWraperHeader .MuiTabs-root {
  min-height: 32px;
}
.tabsWraper .tabsWraperHeader .MuiTabs-root .MuiTabs-flexContainer button.MuiButtonBase-root{
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  padding: 6px 12px;
  min-height: 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  min-width: auto;
  text-transform: capitalize;
  margin-right: 15px;
}
.tabsWraper .tabsWraperHeader .MuiTabs-root .MuiTabs-flexContainer button.MuiButtonBase-root.Mui-selected{
  color: #3361FF;
  background: rgb(51 97 255 / 10%);
}
.cardContentWraper{
  border: 1px solid #F0F2F7 !important;
  border-radius: 8px  !important;
  background-color: #fff  !important;
  box-shadow: none  !important;
  padding: 16px !important;
}
.cardContentWraper .MuiCardContent-root{
  padding: 0px !important;
}
.cardListItem{
  margin: 0px !important;
    padding: 8px !important;
}
.cardListItem span.cardItemprimary {
  font-size: 14px;
  color: #405580;
  font-weight: 600;
  line-height: 17px;
}
.cardListItem p.cardItemsecondary {
  font-size: 14px;
  color: #405580;
}

.inputH-38 {
  height: 38px;
}

.spinner-wrapper.jash {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  background-color: rgb(0 0 0 / 40%);
  align-items: center;
  height:inherit
}

.chipStyle{
  margin-right: 10;  
  border-radius: 5px;
  max-height: 26;
  border-color: "white";
}

.btnSmall {  
  align-items: center;
  /* text-transform: capitalize; */
}

.dialogTitle {
  background: #387DFF;
  color: #fff;
  padding: 5px 8px;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.popoverOverflow{
  overflow: visible !important;
}

.actionButton button {
  padding: 4px;
  /* background-color: transparent !important; */
  font-size: 1.2rem;
  font-weight: 400;
}
.actionButton button:first-child {
  margin-right: 8px;
}
.actionButton button span.material-icons {
  font-size: 1.9rem;
}
.actionButton button span.MuiTouchRipple-root {
  display: none;
}

.chekBoxLabelActive {
  /* margin: 0px; */
  color: #000;
  flex: none;
  margin-top:5 ;
}

.chekBoxLabelActive span.checkBoxprimary {
  padding: 0px;
  margin-right: 6px;
  color: #387DFF;
}

.ReactTable .rt-tbody {
  overflow-x: hidden;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-tbody .rt-td {
  min-width: 35px;
}